<template>
  <div class="page_container">
    <h-banner :bg='banner_url'>
      <div slot="content">
        <div style="color:#fff">
          <div class="banner-title">产品与服务</div>
        </div>
        <p class="banner-desc animate__animated animate__backInRight">开拓创新,赋能行业领导者优化价值、效率和可持续性</p>
      </div>
    </h-banner>

    <div class="page1">
      <div class="clearfix page-container">
        <div class="header">
          <p class="title">将机会转化为价值</p>
          <p class="desc">CISINFO的软件解决方案使资产和运营更安全、更有效，且更具可持续性。我们构建了涵盖资产和运营生命周期的解决方案，将机会转化为商业价值，为行业赋能。 </p>
          <!-- 领先 -->
        </div>

        <el-row :gutter="25">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div
              class="item"
              :style="`background:url(${list1[0]['img']}) no-repeat;background-size: cover;`"
            >
              <p class="content">{{list1[0]['title']}}</p>
              <div
                class="btn animate__animated more"
                @click="handleTo('/industry')"
              >查看全部</div>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div
              class="item"
              :style="`background:url(${list1[1]['img']}) no-repeat;background-size: cover;`"
            >
              <p class="content">{{list1[1]['title']}}</p>
              <div
                class="btn animate__animated more"
                @click="handleTo('/production')"
              >查看全部</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="page2 page-grey">
      <div class="clearfix ">
        <div class="header">
          <p class="title">激励人们去塑造未来的工业软件</p>
          <p class="desc">我们的集成式解决方案专注于激励和赋能人和行业，促进我们的用户与我们的社区的可持续性发展、共享成功和繁荣。 </p>
        </div>

        <el-row :gutter="35">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="6"
            :xl="6"
            v-for="item in list"
            :key="item.title"
          >
            <el-card
              :body-style="{ padding: '15px',background: '#F6F7FB'}"
              el-card
              class="card_item"
            >
              <div :style="`background:url(${item.img}) no-repeat;background-size:cover;width:100%;height:220px`"></div>
              <div class="icon_1">{{item.title}}</div>
              <div class="conntent">
                <p class="title1">{{item.title}}</p>
                <p class="desc1">{{item.desc}}</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      banner_url: require("../../assets/pro/pro-banner.png"),
      list1: [
        { img: require('../../assets/pro/pro-img1.png'), title: '我们的解决方案' },
        { img: require('../../assets/pro/pro-img2.png'), title: '我们的产品' },
        { img: require('../../assets/pro/pro-img3.png'), title: '拥抱您的数字化转型' },
        { img: require('../../assets/pro/pro-img4.png'), title: '使用 CIS-INFO 赋能您的数字化未来' },
        { img: require('../../assets/pro/pro-img5.png'), title: '数字化转型创新' },
        { img: require('../../assets/pro/pro-img6.png'), title: '支持多样化的定制方式' },
      ],
      list: [
        { img: require('../../assets/pro/pro-img3.png'), title: '咨询', desc: '具有很强的信息化项目规划、设计及可行性研究等能力，先后为多个大型国企进行信息化项目的总体规划与设计' },
        { img: require('../../assets/pro/pro-img4.png'), title: '研发', desc: '十多年软件开发经验，熟练掌握各种系统设计和开发工具' },
        { img: require('../../assets/pro/pro-img5.png'), title: '实施', desc: '专业的技术实施团队，熟悉石油石化、装备制造、精细化工等行业生产管理业务。' },
        { img: require('../../assets/pro/pro-img6.png'), title: '运维', desc: '丰富信息系统运维的工作经验，为您保驾护航' },
      ]
    }
  },
  methods: {
    handleTo(val) {
      this.$router.push({ path: val })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .banner-title {
    font-size: 2.2em;
    width: 980px;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 35px;
  }
  .banner-desc {
    width: 980px;
    text-align: center;
    color: #fff;
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner-title {
    font-size: 1.2em;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 35px;
  }
  .banner-desc {
    font-size: 0.825em;
    text-align: center;
    color: #fff;
    padding: 0 20px;
  }
}
.page_container {
  .page1 {
    padding: 0 20px;
  }
  .header {
    padding-bottom: 35px;
    padding-top: 45px;
    .title {
      font-size: 1.8em;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #1e2046;
      line-height: 60px;
      text-align: center;
    }
    .desc {
      color: #666666;
      line-height: 20px;
      font-size: 0.625em;
      text-align: center;
    }
  }
  .item {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
    .content {
      font-size: 1em;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 33px;
    }
    .btn {
      display: inline-block;
      background: linear-gradient(90deg, #4e82fc 0%, #264bf8 100%);
      border-radius: 5px;
      padding: 5px 12px;
      cursor: pointer;
      color: #fff;
      font-size: 0.825em;
      line-height: 20px;
    }
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .page2 {
    padding: 0 35px 65px 35px;
    .card_item {
      margin-bottom: 30px;
      .image {
        z-index: 0;
        position: relative;
      }
      .icon_1 {
        background: #264bf8;
        border-radius: 20px;
        width: 108px;
        text-align: center;
        margin: 0 auto;
        padding: 6px 0;
        color: #fff;
        margin-top: -20px;
        z-index: 99999;
      }
      .conntent {
        height: 115px;
      }
      .title1 {
        font-size: 1em;
        line-height: 3em;
        margin-top: 20px;
      }
      .desc1 {
        font-size: 0.625em;
        color: #666666;
        line-height: 22px;
      }
      &:hover {
        margin-top: -30px;
      }
    }
  }
}
</style>